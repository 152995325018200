import React from 'react'
import { Layout } from 'antd'

import BandcampPlayer from 'react-bandcamp'

import MainLayout from '../components/MainLayout'
import SEO from '../components/seo'

const Music = () => (
	<MainLayout>
		<SEO title='Music' />

		<h1 className='distanced'>
			<span className='symbols'>-:-</span>
			<span>LISTEN</span>
			<span className='symbols'>-:-</span>
		</h1>

		<Layout.Content className='text-center big'>
			<div className='bandcamp-player pneuma'>
				<BandcampPlayer
					album='3708917024'
					bgcol='363636'
					linkcol='F9E658'
					width='350px'
					height='630px'
				/>
			</div>

			<div className='bandcamp-player immortale'>
				<BandcampPlayer
					album='53733211'
					bgcol='F2F2F2'
					linkcol='492F83'
					merch='316800672'
					width='350px'
					height='630px'
				/>
			</div>
		</Layout.Content>
	</MainLayout>
)

export default Music
